import { AfterViewInit, Directive, ElementRef, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import autoAnimate, { AnimationController, AutoAnimateOptions } from '@formkit/auto-animate';

@Directive({
  selector: '[ccAutoAnimate]',
  standalone: true,
})
export class AutoAnimateDirective implements AfterViewInit, OnChanges {
  @Input('ccAutoAnimate')
  options?: Partial<AutoAnimateOptions>;

  @Input()
  animationDisabled = false;

  private animationController: AnimationController | undefined;

  private readonly elementRef = inject(ElementRef);

  ngAfterViewInit(): void {
    this.animationController = autoAnimate(
      this.elementRef.nativeElement,
      this.options || {
        duration: 200,
      },
    );

    !this.animationDisabled ? this.animationController.enable() : this.animationController.disable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['animationDisabled'] && this.animationController) {
      !this.animationDisabled ? this.animationController.enable() : this.animationController.disable();
    }
  }
}
